<template>
  <div class="BigBox">
    <!-- 标识 -->
    <!-- <div style="width: 80%;height: 20px;margin: 2% auto;color: rgba(68, 68, 68, 1);font-size: 22px;margin-top: 3%;">
        <span>靖港古镇</span>
        <img src="../../assets/VRVisit/ShadowPlay/arrow.png" style="height: 14px;margin: 0 1%" />
        <span>非物质文化遗产</span>
          <img src="../../assets/VRVisit/ShadowPlay/arrow.png" style="height:14px;margin: 0 1%" />
        <span>asd </span>
      </div>
      <div class="HeadBox">
        <div class="HeadBoxImg"></div>
        <div class="HeadBoxText"></div>
      </div>
      <embed src='${ctxStatic}/newStyle/9420.mp3' hidden='true' autostart='true' loop='true'> -->
    <div>开发中 敬请期待</div>
    <Navigation_Buttons></Navigation_Buttons>
    <Bottom_Bar></Bottom_Bar>
  </div>
</template>
  
<script>
import $ from "jquery";
import Navigation_Buttons from "../Public/Navigation_Buttons";
import Bottom_Bar from "../Public/Bottom_Bar";
export default {
  components: {
    Navigation_Buttons,
    Bottom_Bar,
  },
  data() {
    return {

    };
  },
  created() {
    this.GetBideoLogList()
    alert('开发中，敬请期待')
    this.$router.push('/VRVisit')
  },
  methods: {
    // 获取页面数据
    GetBideoLogList() {
      var axios = require("axios");
      var data = JSON.stringify({});
      var config = {
        method: "get",
        url: this.$Schttp + "/vtp/app/contentPublish/getImageDetail/" + this.VideoID,

        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          this.BideoLogList = res.data.data
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    SelectVideo(index) {
      this.active = index
    }
  },
};
</script>
  
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.BigBox {
  width: 100%;

  .HeadBox {
    width: 85%;
    min-width: 1200px;
    height: 500px;
    border: 1px solid red;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .HeadBoxImg {
      width: 58%;
      height: 100%;
      border: 1px solid red;
    }

    .HeadBoxText {
      width: 36%;
      height: 100%;
      border: 1px solid red;
    }
  }
}

// 滚动条设置
@remvw: 1920 /100vw;

::-webkit-scrollbar {
  width: 7 / @remvw;
  /*对垂直流动条有效*/
  height: 7 / @remvw;
  /*对水平流动条有效*/
}

/*定义外层滚动槽*/
::-webkit-scrollbar-track-piece {
  border-radius: 6 / @remvw;
}

/*定义内层 滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6 / @remvw;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b;
  opacity: 0.3;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
  display: none;
}

/*定义边角样式*/
::-webkit-scrollbar-corner {
  display: none;
}</style>
  